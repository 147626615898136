@import url("https://fonts.googleapis.com/css?family=Muli:300,400,600,700&display=swap");

* {
  font-family: Inter;
}

.not-found,
.sorry {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 40px;
}

/* override Ant Design with the following syntax: .yourClass.antdClass */

.error.ant-alert {
  margin-bottom: 20px;
}

.form-button.ant-btn {
  width: 100%;
}

/* disable scroll bounce */

html,
body {
  height: 100%;
  overflow: hidden;
}

.app {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
}

/* nav and main content layout */

.main {
  margin-left: 200px !important;
  background: #fafafa !important;
  height: 100vh !important;
}
.dashboard div {
  overflow: visible;
}
.ant-radio-wrapper span {
  white-space: initial;
}

.prefix > p,
.ant-input-group-addon {
  text-transform: uppercase;
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #092C4C !important;
    color: white !important;
  }
  
  .ant-select-dropdown-menu-item {
    padding: 9px 12px !important;
  }

  

  .ant-select-selection:hover, .ant-select-focused {
    border-color: #092C4C !important;
  }

  .ant-select-tree li .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
      color: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #092C4C !important;
      border-color: #092C4C !important;
  }
  .ant-checkbox-disabled .ant-checkbox-inner {
    background-color: #f5f5f5 !important;
  }

  .ant-layout, .ant-layout-content, footer {
    background: #E0E0E0 !important;
  }

.ant-select-dropdown-menu-item {
  padding: 9px 12px !important;
}

.ant-select-selection:hover,
.ant-select-focused {
  border-color: #092c4c !important;
}

.ant-select-tree
  li
  .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  color: white;
}
.wallpaper .ant-layout-content, .wallpaper footer {
  background-color: transparent !important;
}

.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
  background-color: rgb(9, 44, 76) !important;
}
.ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .anticon-filter:hover, .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters:hover .ant-table-filter-icon:hover {
  color: white !important;
}
tbody tr:nth-child(even) td {
  background-color: #F2F2F2;
}
tr th {
  background-color: rgb(9, 44, 76) !important;
  color: white !important;
}